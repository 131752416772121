import iziToast from 'izitoast'
import axios from 'axios'

const colorByType = (type) => {
  if (type == 'error')
    return 'red'
  else if (type == 'warning')
    return 'yellow'
  else if (type == 'info')
    return 'blue'
  else if (type == 'success')
    return 'green'
  else
    return 'blue'
}

const methodName = (type) => {
  return {
    alert: 'error',
    error: 'error',
    info: 'info',
    notice: 'info',
    warning: 'warning'
  }[type] || 'info'
}

export class appUtils {
  /**
   *
   * @param {'alert' | 'success' | 'warning' | 'error' | 'info' | 'information'} type
   * @param {string} message
   */
  static toast(type, message, title=null){
    const color = colorByType(type)
    const method = methodName(type)

    if (typeof method == 'undefined' || method == null)
      return;

    iziToast[method]({
      id: `${type}_flash`,
      title: title || '',
      message: message,
      timeout: 10 * 1000,
      theme: 'dark',
      progressBar: true,
      pauseOnHover: true,
      position: 'topRight',
      //transitionIn: 'bounceInLeft',
      transitionIn: 'flipInX',
      transitionOut: 'flipOutX',
      drag: false
    });
  }

  static axiosSend(url, data, method='post'){
    return axios({
      method,
      url: url,
      data: data,
      withCredentials: true,
      headers: {
        'Content': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').content
      }
    });
  }

}