import Plyr from 'plyr';

const player = new Plyr('#video_player', {
  muted: true,
  autopause: true,
  ratio: '4:3',
  vimeo: {
    autoplay: true,
    portrait: true,
    loop: true,
  },
  youtube: {
    autoplay: true,
    portrait: true,
    loop: true
  }
});